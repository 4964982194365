import {
  createMuiTheme
} from '@material-ui/core'
import {
  darkShadows
} from '@zeliot/common/constants/shadows'

const defaultTheme = createMuiTheme({
  mode: 'light',
  palette: {
    type: 'light',
    primary: {
      // main: '#ff3366',
      main: '#D1291C',

      // light: '#f7bcd5',
      light: '#de6d64',
      dark: '#e3174a'
    },
    secondary: {
      main: '#919191',
      light: '#ffffff',
      dark: '#f0f0f0'
    },
    default: {
      main: '#ffffff'
    },
    link: {
      // main: '#ff3366'
      main: '#D1291C',

    }
  }
})

const darkTheme = createMuiTheme({
  mode: 'dark',
  palette: {
    type: 'dark',
    primary: {
      // main: '#ffffff',
      main: '#D1291C',

      // light: '#f7bcd5',
      light: '#de6d64',


      dark: '#e3174a'
    },
    secondary: {
      main: '#616161',
      light: '#394561',
      dark: '#131824'
    },
    default: {
      main: '#ffffff'
    },
    background: {
      default: '#0d0d0d',
      paper: '#212121'
    },
    link: {
      // main: '#ff3366'
      main: '#D1291C'
    }
  },
  shadows: darkShadows
})

export {
  defaultTheme,
  darkTheme
}
