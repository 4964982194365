// /**
//  * @module root/AppShell/QueryPages
//  * @summary This module exports the QueryPages component
//  */
// import React from 'react'
// import gql from 'graphql-tag'
// import { Query } from 'react-apollo'
// import pagesConfig from 'config/pagesConfig'
// import Loadable from 'react-loadable'
// import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
// import Loader from '@zeliot/common/ui/Loader'
// import getLoginId from '@zeliot/common/utils/getLoginId'
// import { defaultTheme, darkTheme } from '@zeliot/common/constants/theme'
// import { SharedSnackbarProvider } from '@zeliot/common/shared/SharedSnackbar/SharedSnackbar.context'
// import { DownloadProgressDialogProvider } from '@zeliot/common/shared/DownloadProgressDialog/DownloadProgressDialog.context'
// import { setItem, getItem } from '../../../../storage.js'
// import { DEFAULT_USER_CONFIG } from '@zeliot/common/constants/others'

// /**
//  * @summary Lazy loaded Appshell component
//  */
// const AsyncAppShell = Loadable({
//   loader: () => import('./AppShell.jsx'),
//   loading: () => <Loader fullscreen={true} />
// })

// const GET_USER_CONFIGURATION = gql`
//   query($loginId: Int!) {
//     userConfiguration {
//       plan
//       config {
//         configuration
//         theme
//       }
//     }

//     clientDetail(loginId: $loginId) {
//       lat
//       long
//     }

//     getAllClientAds(clientLoginId: $loginId) {
//       imageURL
//       redirectURL
//       placeOfImage
//     }
//   }
// `

// function QueryPages(props) {
//   const [isDarkTheme, setIsDarkTheme] = React.useState(false)

//   function handleThemeChange(val) {
//     setIsDarkTheme(val)
//   }
//   // const pages = data.userConfiguration.plan.features
//   //   .concat([{ key: 'OBD_DASHBOARD' }])
//   //   .map(feat => pagesConfig[feat.key])
//   // const plan = data.userConfiguration.plan.name
//   const plan = DEFAULT_USER_CONFIG.plan.name
//   const pages = DEFAULT_USER_CONFIG.plan.features
//     .concat([{ key: 'OBD_DASHBOARD' }])
//     .map(feat => pagesConfig[feat.key])

//   setItem('plan', plan, 'PERSISTENT')
//   return (
//     <MuiThemeProvider theme={isDarkTheme ? darkTheme : defaultTheme}>
//       <SharedSnackbarProvider>
//         <DownloadProgressDialogProvider>
//           <CssBaseline />
//           <AsyncAppShell
//             {...props}
//             pages={pages}
//             isDarkTheme={isDarkTheme}
//             onThemeChange={handleThemeChange}
//           />
//         </DownloadProgressDialogProvider>
//       </SharedSnackbarProvider>
//     </MuiThemeProvider>
//   )

//   // return (
//   //   <Query
//   //     query={GET_USER_CONFIGURATION}
//   //     variables={{ loginId: 'getLoginId()' }}
//   //   >
//   //     {({ loading, error, data }) => {
//   //       if (loading) return <Loader fullscreen={true} />
//   //       if (error) return <div>Oops</div>

//   //       const pages = data.userConfiguration.plan.features
//   //         .concat([{ key: 'OBD_DASHBOARD' }])
//   //         .map(feat => pagesConfig[feat.key])

//   //       const plan = data.userConfiguration.plan.name
//   //       if (data.clientDetail.isERP) {
//   //         setItem('isERP', isERP, 'PERSISTENT')
//   //         const { isERP } = data.clientDetail
//   //       }
//   //       setItem('plan', plan, 'PERSISTENT')

//   //       return (
//   //         <MuiThemeProvider theme={isDarkTheme ? darkTheme : defaultTheme}>
//   //           <SharedSnackbarProvider>
//   //             <DownloadProgressDialogProvider>
//   //               <CssBaseline />
//   //               <AsyncAppShell
//   //                 {...props}
//   //                 pages={pages}
//   //                 isDarkTheme={isDarkTheme}
//   //                 onThemeChange={handleThemeChange}
//   //               />
//   //             </DownloadProgressDialogProvider>
//   //           </SharedSnackbarProvider>
//   //         </MuiThemeProvider>
//   //       )
//   //     }}
//   //   </Query>
//   // )
// }

// export default QueryPages

/**
 * @module root/AppShell/QueryPages
 * @summary This module exports the QueryPages component
 */
import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import pagesConfig from 'config/pagesConfig'
import Loadable from 'react-loadable'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import Loader from '@zeliot/common/ui/Loader'
import getLoginId from '@zeliot/common/utils/getLoginId'
import { defaultTheme, darkTheme } from '@zeliot/common/constants/theme'
import { SharedSnackbarProvider } from '@zeliot/common/shared/SharedSnackbar/SharedSnackbar.context'
import { DownloadProgressDialogProvider } from '@zeliot/common/shared/DownloadProgressDialog/DownloadProgressDialog.context'
import { setItem, getItem } from '../../../../storage.js'

/**
 * @summary Lazy loaded Appshell component
 */
const AsyncAppShell = Loadable({
  loader: () => import('./AppShell.jsx'),
  loading: () => <Loader fullscreen={true} />
})

const GET_USER_CONFIGURATION = gql`
  query($loginId: Int!) {
    userConfiguration {
      plan
      config {
        configuration
        theme
      }
    }

    clientDetail(loginId: $loginId) {
      lat
      long
      isERP
    }

    getAllClientAds(clientLoginId: $loginId) {
      imageURL
      redirectURL
      placeOfImage
    }
  }
`

function QueryPages(props) {
  let darkThemeState = localStorage.getItem('isDarkTheme')
  let themeState = darkThemeState === 'true' ? true : false
  //let themeState = darkThemeState === 'false' ? true : false

  const [isDarkTheme, setIsDarkTheme] = React.useState(
    themeState ? themeState : false
  )

  function handleThemeChange(val) {
    localStorage.setItem('isDarkTheme', val)
    setIsDarkTheme(val)
  }

  return (
    <Query query={GET_USER_CONFIGURATION} variables={{ loginId: getLoginId() }}>
      {({ loading, error, data }) => {
        if (loading) return <Loader fullscreen={true} />
        if (error) return <div>Oops</div>

        const pages = data.userConfiguration.plan.features
          .concat([{ key: 'OBD_DASHBOARD' }])
          .map(feat => pagesConfig[feat.key])
        // console.log(`QueryPages ~ pages`, data, pages)

        const plan = data.userConfiguration.plan.name
        if (data.clientDetail.isERP) {
          const { isERP } = data.clientDetail
          setItem('isERP', isERP, 'PERSISTENT')
        }
        setItem('plan', plan, 'PERSISTENT')

        return (
          // <MuiThemeProvider theme={isDarkTheme ? darkTheme : defaultTheme}>
          <MuiThemeProvider
            theme={
              localStorage.getItem('isDarkTheme') ? darkTheme : defaultTheme
            }
          >
            <SharedSnackbarProvider>
              <DownloadProgressDialogProvider>
                <CssBaseline />
                <AsyncAppShell
                  {...props}
                  pages={pages}
                  isDarkTheme={isDarkTheme}
                  onThemeChange={handleThemeChange}
                />
              </DownloadProgressDialogProvider>
            </SharedSnackbarProvider>
          </MuiThemeProvider>
        )
      }}
    </Query>
  )
}

export default QueryPages
